itd5ka#splashscreen {
	itd5ka#gamelogo {
		background-image: url('./res/img/logo-512px.png');
		max-height: 512px;
		max-width: 1069px;
		height: 30vh;
		width: 80%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		transform: translateX(2.5vw);
	}

	&.hidden {
		display: none;
	}

	background: linear-gradient(0deg, #ffffff, #ffffff 200%);
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	pointer-events: all;

	& > * {
		margin: 0 auto;
	}

	label {
		font-size: 5vw;
		font-weight: 900;
		color: rgb(0, 128, 0);
		//text-shadow: 0px 0px 5px #00000016;
	}

	#errorlog {
		white-space: pre-line;
		font-size: smaller;
		background-color: green;
		text-align: center;
	}
	#errorlog.attention {
		font-size: medium;
		font-weight: bold;
	}

	/* From cssbuttons.io by @satyamchaudharydev */
	loader {
		--height-of-loader: 4px;
		--loader-color: #ffffff;
		margin: 1em auto;
		width: 60%;
		height: var(--height-of-loader);
		border-radius: 30px;
		background-color: rgba(0, 0, 0, 0.2);
		position: relative;
	}

	loader::before {
		content: '';
		position: absolute;
		background: var(--loader-color);
		top: 0;
		left: 0;
		width: 0%;
		height: 100%;
		border-radius: 30px;
		animation: moving 1s ease-in-out infinite;
	}

	@keyframes moving {
		50% {
			width: 100%;
		}

		100% {
			width: 0;
			right: 0;
			left: unset;
		}
	}
}
