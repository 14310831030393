itd5ka#splashscreen {
  z-index: 10;
  width: 100%;
  height: 100%;
  pointer-events: all;
  background: linear-gradient(0deg, #fff, #fff 200%);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
}

itd5ka#splashscreen itd5ka#gamelogo {
  max-height: 512px;
  max-width: 1069px;
  height: 30vh;
  width: 80%;
  background-image: url("./res/img/logo-512px.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(2.5vw);
}

itd5ka#splashscreen.hidden {
  display: none;
}

itd5ka#splashscreen > * {
  margin: 0 auto;
}

itd5ka#splashscreen label {
  color: green;
  font-size: 5vw;
  font-weight: 900;
}

itd5ka#splashscreen #errorlog {
  white-space: pre-line;
  text-align: center;
  background-color: green;
  font-size: smaller;
}

itd5ka#splashscreen #errorlog.attention {
  font-size: medium;
  font-weight: bold;
}

itd5ka#splashscreen loader {
  --height-of-loader: 4px;
  --loader-color: #fff;
  width: 60%;
  height: var(--height-of-loader);
  background-color: #0003;
  border-radius: 30px;
  margin: 1em auto;
  position: relative;
}

itd5ka#splashscreen loader:before {
  content: "";
  background: var(--loader-color);
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

/*# sourceMappingURL=index.1c634544.css.map */
